import React from "react";
import { Button } from "@mui/material";
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { Profile } from "../../../../../../services/profileService";
import { AssignedUser } from "../../../../../../services/varianceService/model/varianceRequest/AssignedUser";
import { IncidentAssignUserCommand } from "../../../../../../services/varianceService/commands/IncidentAssignUserCommand";
import { useIncidentUnAssignUserMutation, useVarianceSaveMutation } from "../../../../../../services/varianceService/variance-api";
import { removeAssignedUser } from "../../../../../../services/varianceService/model/varianceRequest/VarianceRequestSlice";

type ownProps={
    profile?:Profile;
    assignedUser:AssignedUser;
}

function IncidentUnAssignUserBtn(props:ownProps){
    const varianceRequest = useAppSelector((state)=>state.varianceRequest.varianceRequest);
    const dispatch = useAppDispatch();
    const command = new IncidentAssignUserCommand(varianceRequest.id, props.assignedUser);
    const [unAssign] = useIncidentUnAssignUserMutation();
    const onUnAssignUser = ()=>{
        unAssign(command);
        dispatch(removeAssignedUser(props.assignedUser.upn));
    };
    return (
        <React.Fragment>
            <Button variant='outlined' onClick={()=>onUnAssignUser()} startIcon={<PersonRemoveIcon/>}>unassign</Button>
        </React.Fragment>
    );
}

export {IncidentUnAssignUserBtn};