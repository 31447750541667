import React from "react";
import { Alert, Table } from "@mui/material";
import { AdverseDrug } from "../IncidentType/AdverseDrug/AdverseDrug";
import { IncidentSubTypes } from "../IncidentType/IncidentSubTypes";
import { AnesthesiaOptions } from "../IncidentType/Anesthesia/AnesthesiaOptions";
import { AirwayOptions } from "../IncidentType/Airway/AirwayOptions";
import { BloodProductOptions } from "../IncidentType/BloodProduct/BloodProductOptions";
import { CodeBlueOptions } from "../IncidentType/CodeBlue/CodeBlueOptions";
import { ConsentOptions } from "../IncidentType/Consents/ConsentOptions";
import { DelayOfCareOptions } from "../IncidentType/DelayOfCare/DelayOfCareOptions";
import { TreatmentOptions } from "../IncidentType/Treatment/Treatment";
import { DietaryOptions } from "../IncidentType/Dietary/Dietary";
import { DischargeOptions } from "../IncidentType/Discharge/DischargeOptions";
import { EquipmentOptions } from "../IncidentType/Equipment/EquipmentOptions";
import { HealthCareITOptions } from "../IncidentType/HealthCareIT/HealthCareIT";
import { HospitalAcquiredOptions } from "../IncidentType/HospitalAcquired/HospitalAcquiredOptions";
import { ImagingOptions } from "../IncidentType/Imaging/Imaging";
import { InfectionOptions } from "../IncidentType/Infection/InfectionOptions";
import { IVOptions } from "../IncidentType/IV/IV";
import { LabOptions } from "../IncidentType/Lab/LabOptions";
import { MaterialsOptions } from "../IncidentType/Materials/MaterialsOptions";
import { PatientIDOptions } from "../IncidentType/PatientID/PatientIDOptions";
import { PhysicianBehaviorOptions } from "../IncidentType/PhysicianBehavior/PhysicianBehaviorOptions";
import { AdmissionConditionOptions } from "../IncidentType/AdmissionCondition/AdmissionConditionOptions";
import { PropertyLossOptions } from "../IncidentType/PropertyLoss/PropertyLossOptions";
import { ProvisionOfCareOptions } from "../IncidentType/ProvisionOfCare/ProvisionOfCareOptions";
import { DiagnosticTestErrorOptions } from "../IncidentType/DiagnosticTestError/DiagnosticTestErrorOptions";
import { FacilitiesOptions } from "../IncidentType/Facilities/FacilitiesOptions";
import { FallDetails } from "../IncidentType/Fall/FallDetails";
import { MedicationEvent } from "../IncidentType/MedicationEvent/MedicationEvent";
import { SugicalProcedureDetails } from "../IncidentType/SurgicalProcedure/SurgicalProcedureDetails";
import { SafetyOptions } from "../IncidentType/Safety/SafetyOptions";
import { SterileOptions } from "../IncidentType/Sterile/SterileOptions";

type IncidentTabProps = {
  incident: any;
  canEdit: () => boolean;
};

const IncidentTab: React.FC<IncidentTabProps> = ({ incident, canEdit }) => {
  return (
    <>
      {typeof incident.incidentType !== "string" ||
        (incident.incidentType === "UNKNOWN" && (
          <React.Fragment>
            <Alert color="warning">
              Please choose an Incident Type above.
            </Alert>
            <Table />
          </React.Fragment>
        ))}
      {incident.incidentType === "ADVERSE_DRUG" && (
        <AdverseDrug canEdit={canEdit()} />
      )}
      {incident.incidentType === "ANESTHESIA" && (
        <IncidentSubTypes
          options={AnesthesiaOptions}
          formName="Anesthesia Event"
          freeformName="AnesthesiaFreeForm"
          canEdit={canEdit()}
        />
      )}
      {incident.incidentType === "AIRWAY" && (
        <IncidentSubTypes
          options={AirwayOptions}
          formName="Airway Management"
          freeformName="AirwayFreeForm"
          canEdit={canEdit()}
        />
      )}
      {incident.incidentType === "BLOOD_PRODUCT" && (
        <IncidentSubTypes
          options={BloodProductOptions}
          formName="Blood Product/Transfusion"
          freeformName="BloodProductFreeForm"
          canEdit={canEdit()}
        />
      )}
      {incident.incidentType === "CODE_BLUE" && (
        <IncidentSubTypes
          options={CodeBlueOptions}
          formName="Code Blue/Rapid Response"
          freeformName="CodeBlueFreeForm"
          canEdit={canEdit()}
        />
      )}
      {incident.incidentType === "COMPLAINT" && (
        <IncidentSubTypes
          options={[
            { description: "Complaint", name: "Complaint" },
            { description: "Grievance", name: "Grievance" },
          ]}
          formName="Complaints/Grievances"
          freeformName="ComplaintFreeForm"
          canEdit={canEdit()}
        />
      )}
      <Table />
      {incident.incidentType === "CONSENT" && (
        <IncidentSubTypes
          options={ConsentOptions}
          formName="Consents"
          freeformName="ConsentFreeForm"
          canEdit={canEdit()}
        />
      )}
      {incident.incidentType === "DELAY" && (
        <IncidentSubTypes
          options={DelayOfCareOptions}
          formName="Delay of Care"
          freeformName="DelayOfCareFreeForm"
          canEdit={canEdit()}
        />
      )}
      {incident.incidentType === "DIETARY" && (
        <IncidentSubTypes
          options={DietaryOptions}
          formName="Dietary"
          freeformName="DietaryFreeForm"
          canEdit={canEdit()}
        />
      )}
      {incident.incidentType === "DIAGNOSIS" && (
        <IncidentSubTypes
          options={TreatmentOptions}
          formName="Diagnosis/Treatment"
          freeformName="TreatmentFreeForm"
          canEdit={canEdit()}
        />
      )}
      {incident.incidentType === "DIAGNOSTIC_TEST" && (
        <IncidentSubTypes
          options={DiagnosticTestErrorOptions}
          formName="Diagnostic Test Error"
          freeformName="DiagnosticTestErrorFreeForm"
          canEdit={canEdit()}
        />
      )}
      {incident.incidentType === "DISCHARGE" && (
        <IncidentSubTypes
          options={DischargeOptions}
          formName="Discharge Issue"
          freeformName="DischargeFreeForm"
          canEdit={canEdit()}
        />
      )}
      {incident.incidentType === "ELOPEMENT" && (
        <IncidentSubTypes
          options={[
            { description: "Elopement", name: "Elopement" },
            {
              description: "AMA - Patient Left Against Medical Advise",
              name: "AMA",
            },
            {
              description: "LWBS - Patient Left Without Being Seen",
              name: "LWBS",
            },
          ]}
          formName="Elopement"
          freeformName="ElopementFreeForm"
          canEdit={canEdit()}
        />
      )}
      {incident.incidentType === "EQUIPMENT_FAILURE" && (
        <IncidentSubTypes
          options={EquipmentOptions}
          formName="Equipment/Medical Device"
          freeformName="EquipmentFreeForm"
          canEdit={canEdit()}
        />
      )}
      {incident.incidentType === "FACILITIES" && (
        <IncidentSubTypes
          options={FacilitiesOptions}
          formName="Facilities"
          freeformName="FacilitiesFreeForm"
          canEdit={canEdit()}
        />
      )}
      {incident.incidentType === "FALL" && (
        <FallDetails canEdit={canEdit()} />
      )}
      {incident.incidentType === "FALL_WITH_HARM" && (
        <IncidentSubTypes
          options={[]}
          formName="Fall"
          freeformName="FallFreeForm"
          canEdit={canEdit()}
        />
      )}
      {incident.incidentType === "HEALTHCARE_IT" && (
        <IncidentSubTypes
          options={HealthCareITOptions}
          formName="Healthcare IT"
          freeformName="HealthCareITFreeForm"
          canEdit={canEdit()}
        />
      )}
      {incident.incidentType === "HOSPITAL_ACQUIRED_CONDITION" && (
        <IncidentSubTypes
          options={HospitalAcquiredOptions}
          formName="Hospital Acquired Condition"
          freeformName="HospitalAcquiredFreeForm"
          canEdit={canEdit()}
        />
      )}
      {incident.incidentType === "IMAGING" && (
        <IncidentSubTypes
          options={ImagingOptions}
          formName="Imaging"
          freeformName="ImagingFreeForm"
          canEdit={canEdit()}
        />
      )}
      {incident.incidentType === "INFECTION_CONTROL" && (
        <IncidentSubTypes
          options={InfectionOptions}
          formName="Infection Control"
          freeformName="InfectionFreeForm"
          canEdit={canEdit()}
        />
      )}
      {incident.incidentType === "IV_ACCESS" && (
        <IncidentSubTypes
          options={IVOptions}
          formName="IV/Vascular Access Device"
          freeformName="IVFreeForm"
          canEdit={canEdit()}
        />
      )}
      {incident.incidentType === "LABORATORY" && (
        <IncidentSubTypes
          options={LabOptions}
          formName="Laboratory"
          freeformName="LabFreeForm"
          canEdit={canEdit()}
        />
      )}
      {incident.incidentType === "MEDICATION" && (
        <MedicationEvent canEdit={canEdit()} />
      )}
      {incident.incidentType === "MATERIALS_MANAGEMENT" && (
        <IncidentSubTypes
          options={MaterialsOptions}
          formName="Materials Management/Supply Chain"
          freeformName="MaterialsFreeForm"
          canEdit={canEdit()}
        />
      )}
      {incident.incidentType === "PATIENT_IDENTIFICATION" && (
        <IncidentSubTypes
          options={PatientIDOptions}
          formName="Patient Identification"
          freeformName="PatientIdentificationFreeForm"
          canEdit={canEdit()}
        />
      )}
      {incident.incidentType === "INJURY" && (
        <IncidentSubTypes
          options={[]}
          formName="Injury"
          freeformName="InjuryFreeForm"
          canEdit={canEdit()}
        />
      )}
      {incident.incidentType === "PHYSICIAN_BEHAVIOR" && (
        <IncidentSubTypes
          options={PhysicianBehaviorOptions}
          formName="Physician Behavior"
          freeformName="PhysicianFreeForm"
          canEdit={canEdit()}
        />
      )}
      {incident.incidentType === "PRESENT_ON_ADMISSION" && (
        <IncidentSubTypes
          options={AdmissionConditionOptions}
          formName="Present on Admission Condition"
          freeformName="PresentOnAdmissionFreeForm"
          canEdit={canEdit()}
        />
      )}
      {incident.incidentType === "PROPERTY_LOSS" && (
        <IncidentSubTypes
          options={PropertyLossOptions}
          formName="Property Loss"
          freeformName="PropertyLossFreeForm"
          canEdit={canEdit()}
        />
      )}
      {incident.incidentType === "PROVISION_OF_CARE" && (
        <IncidentSubTypes
          options={ProvisionOfCareOptions}
          formName="Provision of Care"
          freeformName="ProvisionOfCareFreeForm"
          canEdit={canEdit()}
        />
      )}
      {incident.incidentType === "READMISSION" && (
        <IncidentSubTypes
          options={[]}
          formName="Re-admission Unplanned within 30 days"
          freeformName="ReadmissionFreeForm"
          canEdit={canEdit()}
        />
      )}
      {incident.incidentType === "RETAINED_FOREIGN_OBJECT" && (
        <IncidentSubTypes
          options={[]}
          formName="Retained Foreign Object"
          freeformName="RetainedForeignObjectFreeForm"
          canEdit={canEdit()}
        />
      )}
      {incident.incidentType === "RETURN_TO_SURGERY" && (
        <IncidentSubTypes
          options={[]}
          formName="Return to Surgery, Unplanned"
          freeformName="ReturnToSurgeryFreeForm"
          canEdit={canEdit()}
        />
      )}
      {incident.incidentType === "SAFETY" && (
        <IncidentSubTypes
          options={SafetyOptions}
          formName="Safety/Security"
          freeformName="SafetyFreeForm"
          canEdit={canEdit()}
        />
      )}
      {incident.incidentType === "STERILE_PROCESSING" && (
        <IncidentSubTypes
          options={SterileOptions}
          formName="Sterile Processing"
          freeformName="SterileProcessingFreeForm"
          canEdit={canEdit()}
        />
      )}
      {incident.incidentType === "SURGICAL_PROCEDURE" && (
        <SugicalProcedureDetails canEdit={canEdit()} />
      )}
      {incident.incidentType === "SURGICAL_SITE_INFECTION" && (
        <IncidentSubTypes
          options={[]}
          formName="Surgical Site Infection"
          freeformName="SurgicalSiteInfectionFreeForm"
          canEdit={canEdit()}
        />
      )}
      {incident.incidentType === "TRANSFER_TO_HIGHER_CARE" && (
        <IncidentSubTypes
          options={[]}
          formName="Transfer to Higher Level of Care"
          freeformName="TransferHigherLevelCareFreeForm"
          canEdit={canEdit()}
        />
      )}
      {incident.incidentType === "UTILIZATION_REVIEW" && (
        <IncidentSubTypes
          options={[]}
          formName="Utilization Review/Case Management"
          freeformName="UtilizationReviewFreeForm"
          canEdit={canEdit()}
        />
      )}
      {incident.incidentType === "WRONG_PROCEDURE" && (
        <IncidentSubTypes
          options={[
            { description: "Wrong Side", name: "WrongSide" },
            { description: "Wrong Site", name: "WrongSite" },
            { description: "Wrong Patient", name: "WrongPatient" },
          ]}
          formName="Wrong Procedure"
          freeformName="WrongProcedureFreeForm"
          canEdit={canEdit()}
        />
      )}
      {incident.incidentType === "OTHER" && (
        <IncidentSubTypes
          options={[]}
          formName="Other Event"
          freeformName="OtherFreeForm"
          canEdit={canEdit()}
        />
      )}
    </>
  );
};

export { IncidentTab };