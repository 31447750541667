import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
  toggleIdentificationDetailsDialog,
  toggleVarianceSelectedIdentType as toggleVarianceSelectedIndividualType,
} from "../../../../../app/slices/VarianceModuleSlice";
import {
  editVarianceIndividual,  
} from "../../../../../services/varianceService/model/varianceRequest/VarianceRequestSlice";
import { Individual } from "../../../../../services/varianceService/model";
import { VarianceInititiateIndividualCommand } from "./VarianceInititiateIndividualCommand";
import { DefaultIndividual } from "../../../../../services/varianceService/model/individual/Individual";


function VarianceInitiateIdentification(){
  const [individual, setIndividual] = useState(DefaultIndividual());
  const selectedIndividualType = useAppSelector(
    (state) => state.varianceModule.VarianceSelectedIndividualType
  );
  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>
  ) => {
    let newIndividual: Individual = individual;
    newIndividual = { ...newIndividual, [e.target.name]: e.target.value };
    newIndividual.individualType = selectedIndividualType; 
    setIndividual(newIndividual);
  };
  const VarianceIdentificationDetailsDialogOpen = useAppSelector(
    (state) => state.varianceModule.VarianceIdentificationDetailsDialogOpen
  );
  const individualType = useAppSelector(
    (state) => state.varianceModule.VarianceSelectedIndividualType
  );
  const dispatch = useAppDispatch();

  return (
    <React.Fragment>
      <Dialog
        open={VarianceIdentificationDetailsDialogOpen}
      >
        <DialogTitle>Enter Identification Details</DialogTitle>
        <DialogContent>
          {individualType === "Patient" && (
          <TextField
            autoFocus
            margin="dense"
            label="MRN"
            fullWidth
            name="mrn"
            value={individual?.mrn}
            onChange={(e) => {
              handleChange(e);
              dispatch(
                editVarianceIndividual({name: "mrn", value: e.target.value})
              );
            }}
          />)}
          <TextField
            autoFocus
            margin="dense"
            label="First Name"
            fullWidth
            name="firstName"
            value={individual?.firstName}
            onChange={(e) => {
              handleChange(e);
              dispatch(
                editVarianceIndividual({name: "firstName", value: e.target.value})
              );
            }}
          />
          <TextField
            margin="dense"
            label="Last Name"
            fullWidth
            name="lastName"
            value={individual?.lastName}
            onChange={(e) => {
              handleChange(e);
              dispatch(
                editVarianceIndividual({name: "lastName", value: e.target.value})
              );
            }}
          />
          <TextField
            margin="dense"
            label="Age"
            type="number"
            fullWidth
            name="age"
            value={individual?.age}
            onChange={(e) => {
              const inputAge = parseInt(e.target.value);
              if (!isNaN(inputAge) && inputAge >= 0) {
                handleChange(e);
                dispatch(
                  editVarianceIndividual({name: "age", value: e.target.value}));
              }
            }}
          />
          <FormControl margin="dense" fullWidth>
            <InputLabel id="gender-label">Gender</InputLabel>
            <Select
              labelId="gender-label"
              name="sex"
              value={individual?.sex}
              onChange={(e) => {
                handleChange(e);
                dispatch(
                  editVarianceIndividual({name: e.target.name, value: e.target.value})
                );
              }}
              sx={{ width: "200px", maxWidth: "100%" }}
              size="small"
            >
              <MenuItem value=""></MenuItem>
              <MenuItem value="M">Male</MenuItem>
              <MenuItem value="F">Female</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          {VarianceInititiateIndividualCommand(individual)}            
          <Button
            onClick={() => {
              dispatch(toggleIdentificationDetailsDialog());
              dispatch(toggleVarianceSelectedIndividualType(""));
              setIndividual(DefaultIndividual());
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export { VarianceInitiateIdentification };
