import React, { useState } from "react";
import { useParams } from "react-router";
import {
  Grid,
  GridColumn as Column,
  GridDataStateChangeEvent,
} from "@progress/kendo-react-grid";
import { ColumnMenu } from "../../../../utils/ColumnMenu";
import { process, State } from "@progress/kendo-data-query";
import { VarianceWorklistCommandCell } from "./VarianceWorklistCommandCell";
import { VarianceWorklistRequest } from "../../../../services/varianceService/commands/VarianceWorklistRequest";
import { useFindWorklistQuery } from "../../../../services/varianceService/variance-api";
import { useMyProfileQuery } from "../../../../services/profileService/profile-api";

function VarianceWorklistResults() {
  const { status, center } = useParams();
  const profile = useMyProfileQuery();
  const [dataState, setDataState] = useState<State>({});
  const isAdmin = (): boolean => {
    if (profile.data?.role === 'Admin') {
      return true;
    }
    if (profile.data?.role === 'Quality Team') {
      return true;
    }
    if (profile.data?.claimsAccess.find(p => p.code === 'IncidentAdmin') !== undefined) {
      return true;
    }
    if (profile.data?.claimsAccess.find(p => p.code === 'IncidentQualityTeam') !== undefined) {
      return true;
    }
    return false;
  }
  const { data } = useFindWorklistQuery(
    new VarianceWorklistRequest(center!, status!, isAdmin(), profile.data?.identity!)
  );
  const mapCases = () => {
    return data?.map((i) => ({
      ...i,
      varianceStartDate: new Date(i.varianceStartDate),
    }));
  };
  const createDataState = (dataState: State) => {
    return {
      dataState: dataState === undefined ? {} : dataState,
    };
  };
  const dataStateChange = (event: GridDataStateChangeEvent) => {
    let updatedState = createDataState(event.dataState);
    setDataState(updatedState.dataState);
  };

  return (
    <React.Fragment>
      <Grid
        data={process(mapCases() ?? [].slice(0), dataState)}
        {...dataState}
        onDataStateChange={dataStateChange}
        style={{ height: "800px" }}
      >
        <Column cell={VarianceWorklistCommandCell} width="100px" />
        <Column
          title="Incident Number"
          field="incidentNumber"
          columnMenu={ColumnMenu}
          width="200px"
        />
        <Column
          title="Last Name"
          field="lastName"
          columnMenu={ColumnMenu}
          width="225px"
        />
        <Column
          title="First Name"
          field="firstName"
          columnMenu={ColumnMenu}
          width="225px"
        />
        <Column
          title="MRN"
          field="mrn"
          columnMenu={ColumnMenu}
          width="200px"
        />
        <Column
          title="Variance Start Date"
          field="varianceStartDate"
          columnMenu={ColumnMenu}
          width="200px"
          format="{0:d}"
        />
      </Grid>
    </React.Fragment>
  );
}

export { VarianceWorklistResults };
