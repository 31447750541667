import { Individual } from "../individual/Individual";
import { Location } from "../location/Location";
import { AssignedUser, DefaultAssignedUser } from "./AssignedUser";
import { DefaultDirectorResponseAction, DirectorResponseAction } from "./DirectorResponseAction";
import { FollowUp, DefaultFollowUp } from "./FollowUp";
import { IncidentComment } from "./IncidentComment";
import { DefaultIncidentTypeDetail, IncidentTypeDetail } from "./IncidentTypeDetail";
import { MedicationDetail, DefaultMedicationDetail } from "./MedicationDetail";
import { VarianceDocument } from "./VarianceDocuments";


export interface VarianceRequest {
  id: string;
  center: string;
  status: string;
  incidentDate: Date;
  reportingDepartment: string;
  department: string;
  location: Location;
  locationDetails: Location;
  individual: Individual;
  priority: string;
  incidentType: string | "UNKNOWN";
  incidentTypeDetails: IncidentTypeDetail[];
  incidentSubTypeDetails?: IncidentTypeDetail[];
  medicationDetail: MedicationDetail;
  description?: string;
  directorResponse: string;
  directorResponseAction?: DirectorResponseAction;
  followUp?: FollowUp;
  witnesses?: Individual[];
  comments: IncidentComment[];
  assignedTo?: AssignedUser;
  createdBy: string;
  assignedUsers: AssignedUser[];
  severity?: string;
  incidentDisplayNumber?: string;
  incidentDisplayStatus?: string;
  documents: VarianceDocument[];
}

export function DefaultVarianceRequest(): VarianceRequest {
  const sixMonthsAgo = new Date();
  sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

  return {
    id: "",
    center: "",
    status: "",
    incidentDate: sixMonthsAgo,
    reportingDepartment: "",
    department: "",
    priority: "Standard",
    location: new Location(),
    locationDetails: new Location(),
    individual: new Individual(),
    incidentType: "UNKNOWN",
    incidentTypeDetails: [DefaultIncidentTypeDetail()],
    medicationDetail: DefaultMedicationDetail(),
    description: "",
    directorResponse: "",
    directorResponseAction: DefaultDirectorResponseAction(),
    followUp: DefaultFollowUp(),
    witnesses: [],
    comments: [],
    assignedTo: DefaultAssignedUser(),
    createdBy: "",
    assignedUsers: [],
    severity: "",
    incidentDisplayNumber: "",
    incidentDisplayStatus: "",
    documents: [],
  };
}
