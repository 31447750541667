import { Button, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { editBenefits } from "../../../../../services/verifyService/model/verification/VerificationSlice";
import { Validation } from "./Validation";
import { Switch } from "@progress/kendo-react-inputs"
import { PaidToPhysician } from "./PaidToPhysician";
import { UncoveredAmount } from "./eligibilityDetails/UncoveredAmount";
import { EligibilityDataBtn } from "./waystar/EligibilityDataBtn";

function Benefits(){
    const dispatch = useAppDispatch();
    const verification = useAppSelector((state) => state.verification.verification);

    return <React.Fragment>
        <Stack direction='row' gap={10} margin='5px'>
            <Typography variant="h6" className='psn-green'>Benefits</Typography>
            <EligibilityDataBtn/>
        </Stack>
        <Stack direction='row' sx={{marginTop:'10px'}} gap={2}>
            <TextField label='Deductible Amount' name='deductible' value={verification.benefits.deductible} tabIndex={1} onChange={(e)=>dispatch(editBenefits(e))} 
                InputProps={{startAdornment:<InputAdornment position='start'>$</InputAdornment>}}/>
            <TextField label='Out of Pocket Max' name='outOfPocket' tabIndex={3} value={verification.benefits.outOfPocket} onChange={(e)=>dispatch(editBenefits(e))}
                InputProps={{startAdornment:<InputAdornment position='start'>$</InputAdornment>}}/>
            <TextField label='Co-Insurance %' name='coInsurance' tabIndex={5}  value={verification.benefits.coInsurance} onChange={(e) => dispatch(editBenefits(e))}
                size='small' InputProps={{endAdornment:<InputAdornment position='end'>%</InputAdornment>}}/>
        </Stack>
        <Stack direction='row' sx={{marginTop:'15px', marginBottom:'20px'}} gap={2}>
            <TextField label='Deductible Met' name='deductibleMet'tabIndex={2} value={verification.benefits.deductibleMet} onChange={(e)=> dispatch(editBenefits(e))}
                size='small' InputProps={{startAdornment:<InputAdornment position='start'>$</InputAdornment>}}/>
            <TextField label='Out of Pocket Met' name='outOfPocketMet' tabIndex={4} value={verification.benefits.outOfPocketMet} onChange={(e) => dispatch(editBenefits(e))}
                size='small' InputProps={{startAdornment:<InputAdornment position='start'>$</InputAdornment>}}/>
            <TextField label='Co-Pay Amount' name='coPay' tabIndex={6} value={verification.benefits.coPay} onChange={(e) => dispatch(editBenefits(e))}
                size='small' InputProps={{startAdornment:<InputAdornment position='start'>$</InputAdornment>}}/>
        </Stack>
        <Stack direction='row' sx={{marginTop:'0px'}} gap={2} alignItems='flex-start' justifyContent='flex-start'>
            <PaidToPhysician/>
            <UncoveredAmount/>
            <Stack direction='row' gap={2} marginLeft='20px'>
            <span style={{marginTop:'0px', paddingTop:'0px', alignItems:'flex-start', justifyContent:'flex-start'}}>
                <Typography sx={{fontSize:'11px', color:'gray'}}>Apply Ded to OOP</Typography>
                <Switch size='small' name='dedApplyToOOP' value={verification.benefits.dedApplyToOOP} checked={verification.benefits.dedApplyToOOP} onChange={(e)=>dispatch(editBenefits(e))}/>
            </span>
            <span style={{marginTop:'0px',paddingTop:'0px', alignItems:'flex-start'}}>
                <Typography sx={{fontSize:'11px', color:'gray'}}>Apply CoPay to OOP</Typography>
                <Switch size='small' name='coPayApplyToOOP' value={verification.benefits.coPayApplyToOOP} checked={verification.benefits.coPayApplyToOOP} onChange={(e)=>dispatch(editBenefits(e))}/>
            </span> 
            </Stack>         
        </Stack>

        <Validation/>
    </React.Fragment>
}

export {Benefits}