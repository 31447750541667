import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { Add } from "@mui/icons-material";
import { IncidentCommentAddCommand } from "../../../../../services/varianceService/commands/IncidentCommentAddCommand";
import { useIncidentCommentAddMutation } from "../../../../../services/varianceService/variance-api";
import { toggleIncidentCommentAddModal } from "../../../../../app/slices/VarianceModuleSlice";
import { useMyProfileQuery } from "../../../../../services/profileService/profile-api";

function IncidentCommentAddDialog(){
    const dispatch = useAppDispatch();
    const { data } = useMyProfileQuery(); 
    const modalOpen = useAppSelector((state)=>state.varianceModule.IncidentCommentAddModal);
    const varianceId = useAppSelector((state)=>state.varianceRequest.varianceRequest.id);
    const [comment,setComment] = React.useState("");
    const [save] = useIncidentCommentAddMutation();
    const saveComment = ()=>{
        let command = new IncidentCommentAddCommand(varianceId!,comment, data!.identity!);
        save(command);
    }

    return <React.Fragment>

        <Button startIcon={<Add/>} variant='outlined'onClick={()=>dispatch(toggleIncidentCommentAddModal())}>Add a Comment</Button>
        <Dialog open={modalOpen} onClose={()=>setComment('')}>
            <DialogTitle>Add a Comment</DialogTitle>
            <DialogContent>
                <TextField label='Comment' value={comment} onChange={(e)=>setComment(e.target.value)} multiline fullWidth
                   sx={{width:'500px',marginTop:'10px'}} minRows='3'/>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>{dispatch(toggleIncidentCommentAddModal());setComment('');}}>Cancel</Button>
                <Button onClick={()=>{saveComment();dispatch(toggleIncidentCommentAddModal())}}>Save</Button>
            </DialogActions>
            
        </Dialog>
    </React.Fragment>
}

export {IncidentCommentAddDialog}